import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const AndachtUndDienstZitate = () => (
    <InnerPage title="Zitate – Andacht und Dienst" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Andacht und Dienst"
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Andacht und Dienst',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Verborgenen Worte, Persisch 80">
                    O Meine Diener!
                    <br />
                    Ihr seid die Bäume Meines Gartens. Ihr müßt edle, köstliche
                    Früchte tragen, euch und anderen zum Nutzen. Darum soll sich
                    jeder einem Gewerbe oder einem Beruf widmen, denn darin
                    liegt das Geheimnis des Wohlstandes, o ihr Einsichtigen! Der
                    Erfolg hängt von den Mitteln ab, und Gottes Gnade soll euch
                    allgenügend sein. Bäume, die keine Frucht tragen, waren seit
                    je fürs Feuer bestimmt und werden es allezeit sein.
                </NormalQuote>

                <NormalQuote reference="Universales Haus der Gerechtigkeit, 18.12.2014">
                    Wie Ihrer aller Leben deutlich erkennen lässt, muss Andacht
                    – wie notwendig sie auch für das innere Leben des Menschen
                    und wie entscheidend sie für die geistige Entwicklung ist –
                    auch zu Taten führen, die dem inneren Wandel äußeren
                    Ausdruck verleihen.
                </NormalQuote>

                <NormalQuote reference="Universales Haus der Gerechtigkeit, 18.12.2014">
                    Das Konzept des Mashriqu’l-Adhkár ist einzigartig in den
                    Annalen der Religionen und ein Sinnbild für die Lehren des
                    neuen Tages Gottes. Der Mashriqu’l-Adhkár, ein
                    gesellschaftliches Versammlungszentrum, in dem herzliche
                    Zuneigung gefördert wird, steht als allgemeiner Andachtsort
                    allen Bewohnern einer Gegend, unabhängig von
                    Religionszugehö-rigkeit, Herkunft, ethnischer Zugehörigkeit
                    oder Geschlecht, offen, und dient als Oase für tiefes
                    Nachdenken über die geistige Wirklichkeit und die
                    grundlegenden Fragen des Lebens, einschließlich der
                    persönlichen und gemeinschaftlichen Verantwortung für die
                    Verbesserung der Gesellschaft. Männer und Frauen, Kinder und
                    Jugendliche, werden in gleicher Weise in seiner Mitte
                    aufgenommen. Diese einzigartige und umfassende Universalität
                    drückt sich selbst in der Bauweise des Mashriqu’l-Adhkár
                    aus, dessen Entwurf als Bauwerk mit neun Seiten den Eindruck
                    der Vollständigkeit und Vollkommenheit vermittelt, was durch
                    diese Zahl symbolisiert wird.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default AndachtUndDienstZitate;
